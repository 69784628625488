// import {Fancybox} from "@fancyapps/ui";

function showPopupMessage(text) {
    $.colorbox({
        html: '<div class="popup-message">' + text + '</div>',
        maxWidth: '100%',
        maxHeight: '100%',
        opacity: false,
        className: 'colorbox-message'
    });
}

export function initInput() {
    if ($().datepicker) {
        $('input.date').datepicker({
            autoClose: true,
            toggleSelected: false,
            keyboardNav: false,
            minDate: new Date()
        });
    }
    if ($().mask) {
        $('[data-mask="phone"], input[type="tel"]').mask("+7 (999) 999-99-99");
    }
    if ($().styler) {
        setTimeout(function () {
            $(":not(.nostyle)").styler({
                singleSelectzIndex: 10,
                filePlaceholder: 'Файл не выбран',
                fileBrowse: 'Выбрать',
                fileNumber: 'Выбрано файлов: %s',
                onFormStyled: function () {
                    $('.jq-selectbox__trigger-arrow').html('<img src="/local/templates/skd/images/down-arrow.png">');
                    $('.jq-checkbox__div').html('<svg class="icon icon-checkbox"><use xlink:href="#icon-checkbox"></svg>');
                    $('.jq-number__spin.minus').html('-');
                    $('.jq-number__spin.plus').html('+');
                }
            });
        }, 100);
    }
}

export function scroll2element($el, speed, offset, edges) {
    if (speed == undefined) speed = 'slow';
    if (offset == undefined) offset = 50;
    if (edges == undefined) edges = true;

    var scroll = $el.offset().top - offset,
        topEdge = window.pageYOffset,
        bottomEdge = window.pageYOffset + document.documentElement.clientHeight,
        bNeedScroll = edges ? (scroll < topEdge || scroll > bottomEdge) : true;

    if (bNeedScroll) {
        $('html, body').animate({
            scrollTop: scroll + 'px'
        }, speed);
    }
}

/* Одинаковая высота для блоков */
export function setEquleHeight($element) {
    let height = 0;
    $element.each(function (i, e) {
        height = height < $(e).height() ? $(e).height() : height;
    });
    $element.each(function (i, e) {
        $(e).css({height: height + 'px'});
    });
}

